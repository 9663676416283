import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
export const ASSET_NAME = 'terms-of-service';
export const TERMS_VERSION = '2024.02.14';


export const ACCEPT_TERMS_ERROR = 'app/CaptureTermsOfServicePage/ACCEPT__TERMS_ERROR';
export const acceptTermsError = e => ({
  type: ACCEPT_TERMS_ERROR,
  error: true,
  payload: e,
});

export const loadData = (params, search) => dispatch => {
  const pageAsset = { termsOfService: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};


/**
 * Make a phone number update request to the API and return the current user.
 */
export const acceptTerms = params => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;

  const date = new Date();
  const termsAccepted = currentUser.attributes.profile.protectedData.termsAccepted || {};
  termsAccepted[TERMS_VERSION] = date.toISOString();

  return sdk.currentUser
    .updateProfile(
      { protectedData:
        { 
          termsAccepted: termsAccepted
        }
      },
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      // dispatch(acceptTermsError(storableError(e)));
      throw e;
    });
};
